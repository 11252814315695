export const Data = [
    {
      id: 1,
      year: 2016,
      userGain: 122,
    },
    {
      id: 2,
      year: 2017,
      userGain: 500,
    },
    {
      id: 3,
      year: 2018,
      userGain: 800,
    },
    {
      id: 4,
      year: 2018,
      userGain: 2700,
    },
    {
      id: 5,
      year: 2018,
      userGain: 3300,
    },
    {
      id: 6,
      year: 2024,
      userGain: 5500,
    }
  ];